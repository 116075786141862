<template>
  <footer class="c-footer">
    <div class="c-footer__container">
      <div class="c-footer__copyright">Copyright 2021 © | P.IVA 11747580964</div>
      <div class="c-footer__socials">
        <a href="https://www.facebook.com/Intolleranza-zero-102839725131121/" target="_blank">facebook</a>
      </div>
      <div class="c-footer__madeBy">
        Con <span class="material-icons-outlined">favorite_border</span> da <a href="https://www.menosesmas.it/cindy/" target="_blank">Cindy</a>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
</script>

<style lang="scss">
@use "src/assets/css/abstracts/variables" as v;
@use "src/assets/css/abstracts/mixins" as mx;

.c-footer {
  @include mx.use-bg-color(primary);
  @include mx.use-color(white);
  padding: 50px;

  a {
    @include mx.use-color(white);
  }

  &__container {
    @include mx.container-box();
    display: grid;
    grid-template-columns: auto 1fr;
    justify-items: end;
  }

  &__socials {
    display: flex;
  }

  &__madeBy {
    grid-column: 1 / -1;
    margin-top: 20px;
    padding-top: 20px;
    width: 100%;
    text-align: center;
    border-top: 1px dotted white;
  }
}

</style>
