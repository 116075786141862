export const useLazyLoad = {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  beforeMount (el: HTMLElement, binding: any): void {
    function loadBigImage (imgEl: HTMLImageElement) {
      // const previousElementSibling = imgEl.previousElementSibling as HTMLSourceElement

      imgEl.addEventListener('load', function handler () {
        el.classList.add('-isLoaded')
        this.removeEventListener('load', handler)
      })

      imgEl.addEventListener('error', () => console.log('error'))

      imgEl.src = imgEl.dataset.src || ''
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      imgEl.previousElementSibling.srcset = imgEl.dataset.srcSet
    }

    function loadSmallImage () {
      const imageElement = el.querySelector('.js-image') as HTMLImageElement
      if (imageElement) {
        imageElement.addEventListener('load', function handler () {
          setTimeout(() => {
            this.removeEventListener('load', handler)
            loadBigImage(imageElement)
          }, 100)
        })
        imageElement.addEventListener('error', () => console.log('error'))
        imageElement.src = imageElement.dataset.thumbnail || ''
      }
    }

    function handleIntersect (entries: Array<IntersectionObserverEntry>, observer: IntersectionObserver) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          loadSmallImage()
          observer.unobserve(el)
        }
      })
    }

    function createObserver () {
      const options = {
        root: null,
        threshold: 0
      }
      const observer = new IntersectionObserver(handleIntersect, options)
      observer.observe(el)
    }

    if (window.IntersectionObserver && !binding.value.ignoreObserver) {
      createObserver()
    } else {
      loadSmallImage()
    }
  }
}
