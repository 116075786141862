import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import ComingSoon from '@/views/ComingSoon.vue'
import Home from '@/views/Home.vue'
import TheHeader from '@/components/TheHeader.vue'
import TheFooter from '@/components/TheFooter.vue'
const Recipes = () => import(/* webpackChunkName: "recipes" */ '@/views/Recipes.vue')
const routes: Array<RouteRecordRaw> = [
  // { path: '/:pathMatch(.*)', redirect: '/' },
  { path: '/:pathMatch(.*)', redirect: '/coming-soon' },
  {
    path: '/coming-soon',
    name: 'ComingSoon',
    components: { default: ComingSoon }
  },
  {
    path: '/',
    name: 'ComingSoon',
    components: { default: ComingSoon }
  },
  /*  {
    path: '/',
    name: 'Home',
    components: { default: Home, header: TheHeader, footer: TheFooter }
  }, */
  {
    path: '/recipes/:category',
    name: 'Recipes',
    components: { default: Recipes, header: TheHeader, footer: TheFooter }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: '-isActive',
  linkExactActiveClass: '-isExactActive',
  scrollBehavior (to, from, savedPosition) {
    return savedPosition ?? { top: 0 }
  },
  routes
})

export default router
